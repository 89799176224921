import React from "react";
import styled from "styled-components";
import { P, IconCaret } from "@upsolve/ui";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 1em 2em 1em 0em;
  border-bottom: 2px solid ${(props) => props.theme.colors.white[500]};
  color: ${(props) => props.theme.colors.brand[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    color: ${(props) => props.theme.colors.brand[500]};
    line-height: 125%;
  }
  &[data-state="open"] {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 10px;
    path {
      fill: ${(props) => props.theme.colors.brand[500]};
    }
  }
  &:hover,
  label:hover,
  svg:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 45em) {
    padding: 1em 1em 1em 0em;
  }
`;

const StyledText = styled.div`
  padding: 0 2em 1.25em 0em;
  border-bottom: 2px solid ${(props) => props.theme.colors.white[500]};
  ul,
  ol {
    padding: 0 0 0 2em;
  }
  ul {
    list-style-type: initial;
  }
  @media screen and (max-width: 45em) {
    padding: 0 1em 1em 0em;
  }
`;

const StyledRoot = styled(AccordionPrimitive.Root)`
  h3.header {
    margin: unset;
  }
`;

const FAQPanel = ({ faqs = [] }) => (
  <StyledRoot collapsible>
    {faqs.map(({ answer, component, question }, faqIndex) => (
      <AccordionPrimitive.Item key={faqIndex} value={question}>
        <AccordionPrimitive.Header className="header">
          <StyledTrigger>
            <span>{question}</span>
            <IconCaret />
          </StyledTrigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content>
          <StyledText>{component != null ? component : <P>{answer}</P>}</StyledText>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    ))}
  </StyledRoot>
);

export default FAQPanel;
