import React from "react";
import styled from "styled-components";
import { A, ButtonLink, H3, P } from "@upsolve/ui";
import { STATES, stringUtils } from "@upsolve/shared";
import { get } from "lodash";

const LSLDiv = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(235, 235, 235, 1);
  h5,
  p {
    padding: 0;
    margin: 0 0 0.5em 0;
  }
  display: flex;
  justify-content: space-between;
  .listing__image {
    width: 180px;
    padding: 0.5em;
    min-width: 120px;
    max-width: 120px;
    background: ${(props) => props.theme.colors.white[800]};
    border: 1px solid ${(props) => props.theme.colors.white[600]};
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .listing__body {
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .listing__body__description {
    font-size: 13px;
    border-top: 1px solid ${(props) => props.theme.colors.white[600]};
    color: ${(props) => props.theme.colors.gray[900]};
  }
  .ls-name {
    font-size: 21px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.brand[100]};
  }
  .ls-title {
    color: ${(props) => props.theme.colors.brand[100]};
  }
  .ls-address {
    color: ${(props) => props.theme.colors.gray[900]};
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
  .ls-biography,
  .ls-languages,
  .ls-practicing-since,
  .ls-practicing-in,
  .ls-contact {
    color: ${(props) => props.theme.colors.gray[700]};
    font-size: 14px;
    line-height: 18px;
  }
  .listing__body__extras {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
    padding: 0 0.5em;
    color: ${(props) => props.theme.colors.gray[900]};
    &,
    p {
      font-size: 13px;
    }
  }
  .listing__actions {
    background: ${(props) => props.theme.colors.white[800]};
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    padding: 1em 0.5em;
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: 0.5em;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    flex-direction: column;
    .listing__image {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      max-height: 64px;
      img {
        height: 40px;
      }
    }
    .listing__body {
      flex-direction: column;
      padding: 2em 2em 0.5em;
      &__extras {
        padding: 0.5em 0;
        min-width: 100%;
      }
      .ls-address {
        display: flex;
        flex-direction: row;
        & > span {
          margin-right: 4px;
        }
      }
    }
    .listing__actions {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

const LegalServiceListing = (props) => {
  let languages = (props.languages || []).map((l, i, a) => `${l.language}${a.length - 1 !== i ? ", " : ""}`);
  let practicingSince = Math.max(...(props.jurisdictions || []).map((j) => Number(j.year)));
  let practicingIn = (props.jurisdictions || [])
    .map((j) => STATES[j.state] || j.state)
    .filter((j) => !/Court/i.test(j) && !/Circuit/i.test(j) && !/Supreme/i.test(j))
    .join(", ");
  let personalWebsite = get(
    (props.websites || []).filter((w) => w.name == null && !/justia/i.test(w.url)),
    "[0].url"
  );
  let phoneNumber;
  if (props.phoneNumber) {
    const normalizedPhone = props.phoneNumber.replace(/^\+1/, "").replace(/[^\d]/g, "");
    phoneNumber = `(${normalizedPhone.slice(0, 3)}) ${normalizedPhone.slice(3, 6)}-${normalizedPhone.slice(6)}`;
  } else {
    phoneNumber = null;
  }
  return (
    <LSLDiv>
      <div className="listing__image">
        <img src={get(props, "logo.url") || "/images/blob-attorneyHeadShot.png"} />
      </div>
      <div className="listing__body">
        <div className="listing__body__details">
          <H3 className="ls-name">
            <A href={props.url}>
              {(props.businessName || "").length < 6 ? `${props.firstName} ${props.lastName}` : props.businessName}
            </A>
          </H3>
          <small className="ls-title">
            {`${props.address.city}, ${props.address.state} Bankruptcy Attorney${
              practicingSince > 1900 ? ` with ${new Date().getFullYear() - practicingSince} Years Experience` : ""
            }`}
          </small>
          {(languages || "").length > 0 && (
            <P className="ls-languages">
              <b>Languages</b>: {languages}
            </P>
          )}
          {practicingSince > 1900 && (
            <P className="ls-practicing-since">
              <b>Practicing Since</b>: {practicingSince}
            </P>
          )}
          {practicingIn.length > 0 && (
            <P className="ls-practicing-in">
              <b>Practicing In</b>: {stringUtils.ellipseString(practicingIn)}
            </P>
          )}
          {props.biography != null && <P className="ls-biography">{stringUtils.ellipseString(props.biography, 320)}</P>}
          {phoneNumber != null && (
            <P className="ls-contact">
              <b>Phone:</b> {phoneNumber}
            </P>
          )}
          {personalWebsite != null && (
            <P className="ls-contact">
              <b>Website:</b>{" "}
              {personalWebsite.replace(/(.*)\/\//, "").replace(/[\/.co|\/.net|\.com|\.org|\.gov]\/(.*)/, "")}
            </P>
          )}
        </div>
        <div className="listing__body__extras">
          <P className="ls-address">
            <span>{props.address.street1}</span>
            {(props.address.street2 || "").length > 0 && <span>{props.address.street2}</span>}
            <span>{props.address.city}</span>
            <span>{props.address.state}</span>
            <span>{props.address.zipcode}</span>
          </P>
          {props.distance != null && (
            <small>
              {((d) => {
                if (d < 5) return `${Number(d).toFixed(1)}mi`;
                if (d > 100) return "100+";
                return `${Math.floor(d)}mi`;
              })(props.distance)}{" "}
              Away
            </small>
          )}
        </div>
      </div>
      {props.actions !== false && (
        <div className="listing__actions">
          {props.url != null && (
            <ButtonLink size="sm" buttonType="white" href={props.url}>
              <b>View Profile</b>
            </ButtonLink>
          )}
          {props.phoneNumber != null && (
            <ButtonLink size="sm" buttonType="white" href={`tel:${props.phoneNumber}`}>
              <b>Call Attorney</b>
            </ButtonLink>
          )}
        </div>
      )}
    </LSLDiv>
  );
};

export default LegalServiceListing;
