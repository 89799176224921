import React from "react";
import { A as StyledA, ButtonLink as StyledButtonLink } from "@upsolve/ui";

async function handleClick(ev, props) {
  ev.preventDefault();
  if (props.onClick) {
    await props.onClick();
  } else {
    console.warn("No onClick property used with special onClick anchor link component.");
  }
  if (props.target === "_blank") {
    window.open(props.href, "_blank");
  } else {
    window.location.href = props.href;
  }
}

export const A = (props) => (
  <StyledA {...props} onClick={(ev) => handleClick(ev, props)}>
    {props.children}
  </StyledA>
);

export const ButtonLink = (props) => (
  <StyledButtonLink {...props} onClick={(ev) => handleClick(ev, props)}>
    {props.children}
  </StyledButtonLink>
);
