import React from "react";
import styled from "styled-components";
import { H4, H6, P } from "@upsolve/ui";

const Callout = ({ component }) => <StyledCallout>{component}</StyledCallout>;

const StyledCallout = styled.div`
  width: auto;
  border-left: 5px solid ${(props) => props.theme.colors.brand[500]};
  padding: 10px 20px 10px 20px;
  p {
    font-size: 20px;
    line-height: 1.5;
  }
  background: ${(props) => props.theme.colors.brand[900]};
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    margin: 1em auto;
  }
`;

export default Callout;
