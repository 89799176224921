import styled, { css } from "styled-components";

const TableCellCSS = css`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  ${(props) => {
    if (props.hideSmall) {
      return css`
        @media screen and (max-width: 45em) {
          display: none;
        }
      `;
    }
  }}
  a, span, button {
    display: inline;
  }
`;

export const TD = styled.td`
  ${TableCellCSS}
`;

export const TH = styled.th`
  ${TableCellCSS}
`;
