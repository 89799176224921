import React from "react";
import styled from "styled-components";
import { STATES } from "@upsolve/shared";
import { theme } from "@upsolve/ui";

import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";
import { MyUpsolveButtonLink } from "../Links/MyUpsolveLink";
import trackComponentAction from "../analytics/trackComponentAction";

const trackingProps = {
  componentName: "CTAInsertSimple",
  componentVersion: 0,
};

const CTAInsertSimpleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.brand["500"]};
  border-bottom: 3px solid ${theme.colors.brand["200"]};
  padding: 1.25em 2em;
  margin: 2em 0;
  color: white;
  border-radius: 6px;
  .header {
    font-size: 26px;
    line-height: 130%;
    font-weight: 700;
  }
  a {
    font-size: 20px;
    font-weight: 700;
    margin: 0.5em 0 0;
    @media (max-width: 45em) {
      width: 100%;
    }
  }
  @media (max-width: 45em) {
    margin: 3em 0;
    padding: 2em;
  }
`;

const CTAInsertSimple = (props) => (
  <CTAInsertSimpleWrapper>
    <span className="header">
      <u>Upsolve operates in{props.state != null ? ` ${STATES[props.state.toUpperCase()]}!` : " your area!"}</u> If
      you're in debt, bankruptcy is a life line.
    </span>
    <p>
      Our nonprofit helps you file for bankruptcy for free, guiding you every step of the way. Learn if bankruptcy is
      right for you.
    </p>
    <MyUpsolveButtonLink
      buttonType="white"
      href={getMyUpsolveEntryUrl({
      })}
      onClick={() =>
        trackComponentAction({
          ...trackingProps,
          actionId: "ctaInsertSimple.myUpsolveNavigation",
          actionMethod: "click",
          pageContext: props.trackingPageContext
        })
      }
    >
      Learn More
    </MyUpsolveButtonLink>
  </CTAInsertSimpleWrapper>
);

export default CTAInsertSimple;
