import { format } from "date-fns";
import React from "react";
import { getStatePovertyLevel, STATES } from "@upsolve/shared";

import SimpleTable from "../Tables/SimpleTable";
import { TD, TH } from "../Tables/TableCells";
import { H5, H6 } from "../Type/Display.h";

const StatePovertyLevelsChart = ({ state }) => (
  <SimpleTable>
    <thead>
      <tr>
        <TH colSpan="3" className="header">
          <H5>
            {STATES[state.toUpperCase()]} Fee Waiver Eligibility for Cases Filed In {format(new Date(), "yyyy")}
          </H5>
          <H6 as="p">Eligible for fee waiver when under 150% the poverty level.</H6>
        </TH>
      </tr>
      <tr>
        <TH>Household Size</TH>
        <TH>State Poverty Level</TH>
        <TH>Fee Waiver Limit (150% PL)</TH>
      </tr>
    </thead>
    <tbody>
      {Array.prototype.map.call([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (size, index) => (
        <tr key={index}>
          <TD>{size}</TD>
          <TD>
            $
            {getStatePovertyLevel(state, size).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TD>
          <TD>
            $
            {getStatePovertyLevel(state, size, 1.5).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TD>
        </tr>
      ))}
    </tbody>
  </SimpleTable>
);

export default StatePovertyLevelsChart;
