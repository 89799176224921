import React from "react";
import { DISTRICTS, STATES } from "@upsolve/shared";
import { Table } from "@upsolve/ui";

import judges from "../../../content/judges.json";
import { TD, TH } from "../Tables/TableCells";
import { H5 } from "../Type/Display.h";

const JudgesList = ({ district, state }) => {
  if (district == null && state == null) return null;

  const filteredJudges = judges.filter((judge) => {
    if (state != null) return new RegExp(`^${state.toLowerCase()}`).test(judge.district);
    if (district != null) return district.toLowerCase() === judge.district.toLowerCase();
    return false;
  });

  return (
    <Table size="sm">
      <thead>
        <tr>
          <TH colSpan="2" className="header">
            <H5>{STATES[state.toUpperCase()]} Bankruptcy Judges</H5>
          </TH>
        </tr>
        <tr>
          <TH>District</TH>
          <TH>Judge Name</TH>
        </tr>
      </thead>
      <tbody>
        {Array.prototype.map.call(filteredJudges, (judge, index) => (
          <tr key={index}>
            <TD>{DISTRICTS[judge.district] || ""}</TD>
            <TD>Hon. {judge.name}</TD>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default JudgesList;
