import React from "react";
import { legalServiceProviders } from "@upsolve/shared";

import ImageStaticMap from "../Media/ImageStaticMap";

const LegalAidOrgsList = ({ state }) => {
  return (
    <div>
      {Array.prototype.map.call(
        legalServiceProviders.filter((lsp) => lsp.state.toLowerCase() === state.toLowerCase()),
        (la) => (
          <div key={la.name}>
            <p>
              <a href={la.url || ""} target="_blank" rel="noopener noreferrer">
                {la.name}
              </a>
              <br />
              {la.phone}
              <br />
              {la.address}
              <ImageStaticMap alt={`${la.name} Location`} address={la.address} />
            </p>
          </div>
        )
      )}
      <div>
        <p>
          <a href="/">Upsolve</a>
          <br />
          Nationwide Service (NYC Office)
        </p>
        <ImageStaticMap
          alt="Upsolve Location"
          number="150"
          street="Court Street"
          city="New York City"
          state="NY"
          zipcode="11201"
        />
      </div>
    </div>
  );
};

export default LegalAidOrgsList;
