import { format } from "date-fns";
import React from "react";
import { getStateMedianFamilyIncome, STATES } from "@upsolve/shared";

import SimpleTable from "../Tables/SimpleTable";
import { TD, TH } from "../Tables/TableCells";
import { H5, H6 } from "../Type/Display.h";

const StateMedianIncomeChart = ({ state }) => (
  <SimpleTable>
    <thead>
      <tr>
        <TH colSpan="3" className="header">
          <H5>
            {STATES[state.toUpperCase()]} Median Income Standards for Means Test for Cases Filed In{" "}
            {format(new Date(), "yyyy")}
          </H5>
        </TH>
      </tr>
      <tr>
        <TH>Household Size</TH>
        <TH>Monthly Income</TH>
        <TH>Annual Income</TH>
      </tr>
    </thead>
    <tbody>
      {Array.prototype.map.call([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (size, index) => (
        <tr key={index}>
          <TD>{size}</TD>
          <TD>
            $
            {(getStateMedianFamilyIncome(state, size) / 12).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TD>
          <TD>
            $
            {getStateMedianFamilyIncome(state, size).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TD>
        </tr>
      ))}
    </tbody>
  </SimpleTable>
);

export default StateMedianIncomeChart;
