import styled, { css } from "styled-components";
import { theme } from "@upsolve/ui";

const Input = styled.input`
  width: 100%;
  outline: none;
  padding: 0.8em 1em;
  font-size: 0.9em;
  border: 1px solid ${theme.colors.white["500"]};
  border-bottom: 2px solid ${theme.colors.white["300"]};
  border-radius: 4px;
  &::placeholder {
    color: ${theme.colors.gray["900"]};
  }
  ${(props) => {
    if (props.disabled) {
      return css`
        background: ${theme.colors.white["500"]};
      `;
    }
  }}
  ${(props) => {
    if (props.size === "xs") {
      return `
        padding: .8em .5em;
        font-size: .8em;
      `;
    } else if (props.size === "sm") {
      return `
        padding: .8em .5em;
        font-size: .8em;
      `;
    } else if (props.size === "md" || !props.size) {
      return `
        padding: .8em .5em;
        font-size: 1em;
      `;
    } else if (props.size === "lg") {
      return `
        padding: .8em;
        font-size: 1.2em;
        border-radius: 12px;
      `;
    } else if (props.size === "xl") {
      return `
        width: 100%;
        padding: .8em .5em;
        font-size: 1.6em;
        text-align: center;
        border-radius: 12px;
      `;
    }
    return `
      padding: .8em .5em;
      font-size: 1.1em;
    `;
  }}
`;

export default Input;
