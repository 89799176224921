import { theme } from "@upsolve/ui";

export default function composeStaticMapUrl({
  bearing,
  height = 360,
  latitude,
  longitude,
  markers = [],
  pitch,
  style = "streets-v11",
  width = 480,
  zoom = 13,
}) {
  // If no lat/lon, bail
  if (latitude == null || longitude == null) return null;
  // Setup location strings separated by ","
  const locParams = [longitude, latitude, zoom];
  if (bearing) locParams.push(bearing);
  if (pitch) locParams.push(pitch);
  const markersString = markers
    .filter((m) => m.latitude && m.longitude)
    .map((marker) => `pin-s-marker+${theme.colors.brand[500].replace("#", "").toLowerCase()}(${longitude},${latitude})`)
    .join(",");
  return `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${
    markersString.length > 0 ? `${markersString}/` : ""
  }${locParams.join(",")}/${width}x${height}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`;
}
