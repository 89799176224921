import React from "react";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

import Input from "../Inputs/Input.input";
import InputRow from "../Inputs/InputRow.div";
import trackComponentAction from "../analytics/trackComponentAction";
import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";
import { MyUpsolveButtonLink } from "../Links/MyUpsolveLink";

const trackingProps = {
  componentName: "CTAInsertZipcode",
  componentVersion: 0,
};

const CTAZipcodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.brand["500"]};
  border-bottom: 3px solid ${theme.colors.brand["200"]};
  padding: 1.25em 2em;
  margin: 2em 0;
  color: white;
  border-radius: 6px;
  .header {
    font-size: 26px;
    line-height: 130%;
    font-weight: 700;
  }
  div {
    margin-top: 0.5em;
  }
  a {
    font-size: 20px;
    font-weight: 700;
  }
  @media (max-width: 45em) {
    margin: 3em 0;
    padding: 2em;
    a {
      margin: 0.5em 0 0;
      width: 100%;
    }
  }
`;

const CTAZipcode = (props) => (
  <CTAZipcodeWrapper>
    <span className="header">If you're in debt, bankruptcy is a life line.</span>
    <p>
      Our nonprofit helps you file for bankruptcy for free, guiding you every step of the way. Enter your zip code and
      see if we can help you.
    </p>
    <InputRow>
      <Input type="text" placeholder="Zipcode" size="lg" />
      <MyUpsolveButtonLink
        buttonType="white"
        href={getMyUpsolveEntryUrl()}
        onClick={() =>
          trackComponentAction({
            ...trackingProps,
            actionId: "ctaInsertZipcode.myUpsolveNavigation",
            actionMethod: "click",
            pageContext: props.trackingPageContext,
          })
        }
      >
        Check Availability
      </MyUpsolveButtonLink>
    </InputRow>
  </CTAZipcodeWrapper>
);

export default CTAZipcode;
