import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Portal from "../overlays/Portal";
import SharedModal from "../overlays/ShareModal";

const ContentSectionFooter = () => {
  const [openShareModal, setOpenShareModal] = useState(false);
  return (
    <Fragment>
      <ContentSectionFooterWrapper>
        <small className="content-section-footer__back-to-top">
          <a href="#article">↑ Back to top</a>
        </small>
        <small className="content-section-footer__share" onClick={() => setOpenShareModal(true)}>
          Share Article [⬈]
        </small>
      </ContentSectionFooterWrapper>
      {openShareModal && (
        <Portal>
          <SharedModal onClose={() => setOpenShareModal(false)} />
        </Portal>
      )}
    </Fragment>
  );
};

const ContentSectionFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  small {
    margin-top: 0.5em;
    font-size: 12px;
    &,
    & > a {
      color: ${(props) => props.theme.colors.gray[500]};
      text-decoration: underline;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export default ContentSectionFooter;
