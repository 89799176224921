import React, { Fragment } from "react";
import { get } from "lodash";

import P from "../Type/Paragraph.p";
import { H5 } from "../Type/Display.h";

const DistrictRequirementsSection = ({ district }) => {
  return (
    <div>
      {district.guide != null && district.guide.length > 0 ? (
        <div>
          <P>{district.guide}</P>
        </div>
      ) : (
        <P>The {district.name} has no special forms.</P>
      )}
      <H5>Other Details</H5>
      <ul>
        {district.expectToKeepCopies === true && <li>Expect to keep copies of your documents.</li>}
        {district.copiesRequired > 1 ? (
          <li>You will need {district.copiesRequired} copies of your forms.</li>
        ) : (
          <li>You will need one copy of your forms.</li>
        )}
        {district.paystubsRequired && <li>Expect to file your paystubs.</li>}
        {district.rideThrough != null && district.rideThrough.length > 0 && (
          <li>Ridethrough expectations: {district.rideThrough}</li>
        )}
        {district.mailInPetitionRequired === true && <li>Expect to mail your petition to the trustee.</li>}
      </ul>
      {(get(district, "forms") || []).length > 0 && (
        <Fragment>
          <H5>{district.name} Forms</H5>
          <ul>
            {Array.prototype.map.call(get(district, "forms") || [], (form, index) =>
              form.url != null ? (
                <li key={index}>
                  <a href={form.url} target="_blank" rel="noopener noreferrer">
                    {form.name}
                    {form.mandatory === true ? " (Mandatory)" : ""}
                  </a>
                </li>
              ) : (
                <li key={index}>
                  {form.name}
                  {form.mandatory === true ? " (Mandatory)" : ""}
                </li>
              )
            )}
          </ul>
        </Fragment>
      )}
    </div>
  );
};

export default DistrictRequirementsSection;
