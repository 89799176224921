import copy from "copy-to-clipboard";
import React, { useState } from "react";
import styled from "styled-components";
import { ACSS, H4, IconEnvelope, IconFacebook, IconLedger, IconTwitter, IconX } from "@upsolve/ui";

import IconChatBubble from "../../../static/images/icon-chat-bubble.svg";
import ModalBackground from "./ModalBackground";
import trackComponentImpression from "../analytics/trackComponentImpression";
import trackComponentAction from "../analytics/trackComponentAction";
import { A } from "../analytics/LinksWithOnClick";

function getUrl() {
  return window.location.href;
}

function getTitle() {
  return typeof document !== "undefined" ? document.title.replace("| Upsolve", "").trim() : undefined;
}

const trackingProps = {
  componentName: "ShareModal",
  componentVersion: 0,
};

const ShareModal = (props) => {
  // Tracking - Ignore viewport check. It's a fullscreen fixed positioned element
  const [impressionTracked, setImpressionTracked] = useState(false);
  if (!impressionTracked) {
    setImpressionTracked(true);
    trackComponentImpression(trackingProps);
  }

  // TODO: Test with native share via navigator.share()? Airbnb doesn't
  // TODO: Messenger & WhatsApp
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  function linkCopier() {
    setIsLinkCopied(true);
    copy(getUrl());
    setTimeout(() => setIsLinkCopied(false), 2000);
  }
  return (
    <ShareModalStyled>
      <ModalBackground onClick={() => props.onClose()}>
        <div className="share-modal__panel" onClick={(ev) => ev.stopPropagation()}>
          <div className="close">
            <IconX onClick={() => props.onClose()} />
          </div>
          <div>
            <H4>Share this article with others in your life and community</H4>
          </div>
          <div>
            <A
              href={`https://www.facebook.com/sharer/sharer.php?u=${getUrl()}&t=${getTitle()}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackComponentAction({ ...trackingProps, actionId: "shareModal.share", actionMethod: "click" })
              }
            >
              <IconFacebook /> <span>Facebook</span>
            </A>
          </div>
          <div>
            <A
              href={`sms:?body=${getTitle()}&body="${getTitle()}" via Upsolve (${getUrl()})`}
              onClick={() =>
                trackComponentAction({ ...trackingProps, actionId: "shareModal.share", actionMethod: "click" })
              }
            >
              <IconChatBubble />
              <span>SMS</span>
            </A>
          </div>
          <div>
            <A
              href={`https://twitter.com/intent/tweet?via=upsolvebk&text=${getTitle()}&url=${getUrl()}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackComponentAction({ ...trackingProps, actionId: "shareModal.share", actionMethod: "click" })
              }
            >
              <IconTwitter /> <span>Twitter</span>
            </A>
          </div>
          <div>
            <A
              href={`mailto:?subject=${getTitle()}&body="${getTitle()}" via Upsolve (${getUrl()})`}
              onClick={() =>
                trackComponentAction({ ...trackingProps, actionId: "shareModal.share", actionMethod: "click" })
              }
            >
              <IconEnvelope /> <span>Email</span>
            </A>
          </div>
          <div
            onClick={() => {
              linkCopier();
              trackComponentAction({ ...trackingProps, actionId: "shareModal.share", actionMethod: "click" });
            }}
          >
            <IconLedger /> <span>{isLinkCopied ? "Link Copied" : "Copy Link"}</span>
          </div>
        </div>
      </ModalBackground>
    </ShareModalStyled>
  );
};

const ShareModalStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  .share-modal__panel {
    width: 370px;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 2em;
    background: white;
    border-radius: 4px;
    h4 {
      line-height: 120%;
    }
    & > div {
      margin: 0 2em;
      padding: 1.5em 0;
      &:not(:first-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.white[500]};
      }
      & > *:not(h4) {
        ${ACSS}
        font-weight: 500;
        font-size: 16px;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
          color: ${(props) => props.theme.colors.brand[500]};
        }
      }
      &:not(.close) svg {
        width: 20px;
        height: 20px;
        path {
          fill: ${(props) => props.theme.colors.black[500]};
        }
        margin-right: 8px;
      }
      &,
      & > a {
        display: flex;
        align-items: center;
      }
    }
    .close {
      padding-bottom: 0;
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: ${(props) => props.theme.colors.gray[700]};
        }
      }
    }
    @media (max-width: 45em) {
      width: 100%;
      margin: 0;
    }
  }
`;

export default ShareModal;
