import React, { useState, useEffect } from "react";
import { STATES } from "@upsolve/shared";
import { Table } from "@upsolve/ui";
import axios from "axios";

import { TH } from "../Tables/TableCells";
import { H5 } from "../Type/Display.h";

const TrusteesList = ({ district, state }) => {
  const [trustees, setTrustees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchTrustees = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${UPSOLVE_API_URL}/v1/content/trustees`, {
          cancelToken: source.token,
        });
        setTrustees(response.data);
        setError(null);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          setError("Failed to fetch trustees data");
          console.error(`Error fetching trustees (${UPSOLVE_API_URL}/v1/content/trustees):`, err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTrustees();

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, []);

  if (district == null && state == null) return null;
  if (loading) return <div>Loading trustees...</div>;
  if (error) return <div>Error: {error}</div>;

  const filteredTrustees = trustees.filter((trustee) => {
    if (state != null) return new RegExp(`^${state.toLowerCase()}`).test(trustee.district);
    return false;
  });

  return (
    <Table size="sm">
      <thead>
        <tr>
          <TH colSpan={2} className="header">
            <H5>{STATES[state.toUpperCase()]} Trustees</H5>
          </TH>
        </tr>
        <tr>
          <TH>Trustee</TH>
          <TH>Contact Info</TH>
        </tr>
      </thead>
      <tbody>
        {filteredTrustees.map((trustee, index) => (
          <tr key={index}>
            <td>{trustee.name}</td>
            <td>
              {trustee.email}
              <br />
              {trustee.phoneNumber}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TrusteesList;