import styled from "styled-components";

const SimpleTable = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: left;
  tbody {
    background: white;
  }
  thead {
    background: ${(props) => props.theme.colors.white["800"]};
    color: initial;
    th.header {
      background: ${(props) => props.theme.colors.white["900"]};
      padding-left: 0;
      padding-right: 0;
      border: none;
      border-bottom: 4px solid ${(props) => props.theme.colors.gray["900"]};
      h5 {
        color: initial;
      }
      h6 {
        color: ${(props) => props.theme.colors.gray["900"]};
      }
    }
  }
  a {
    text-decoration: none;
  }
  p {
    font-size: ${(props) => (props.space === "tight" ? ".8em;" : ".9em;")};
  }
  th,
  td {
    padding: ${(props) => (props.space === "tight" ? ".2em .6em;" : ".8em 1em;")};
    font-size: ${(props) => (props.space === "tight" ? ".8em;" : ".9em;")};
    border: 1px solid ${(props) => props.theme.colors.white["700"]};
  }
  span,
  button {
    margin: 0 0.5em;
  }
`;

export default SimpleTable;
