import { get } from "lodash";
import React from "react";
import composeStaticMapUrl from "../maps/composeStaticMapUrl";

const ImageStaticMap = (props) => {
  const latitude = props.latitude || get(props, "address.latitude");
  const longitude = props.longitude || get(props, "address.longitude");
  const staticMapUrl = composeStaticMapUrl({
    height: props.height || 240,
    latitude,
    longitude,
    markers: [
      {
        latitude,
        longitude,
      },
    ],
    width: props.width || 360,
    zoom: props.zoom,
  });

  return !staticMapUrl ? null : <img alt={props.alt} src={staticMapUrl} />;
};

export default ImageStaticMap;
