import styled from "styled-components";

const InputRow = styled.div`
  display: flex;
  width: 100%;
  * {
    border-radius: 0;
  }
  input {
    border-radius: 0.25em 0 0 0.25em;
  }
  button:last-of-type {
    border-radius: 0 0.25em 0.25em 0;
  }
  label {
    flex-grow: 1;
  }
  button {
    flex-grow: 2;
  }
  @media (max-width: 45em) {
    flex-direction: column;
    input,
    button {
      border-radius: 0.25em !important;
    }
  }
`;

export default InputRow;
